import React from 'react';
import { createRoot } from 'react-dom/client';
import './Public/Css/main.css';
import './Public/Css/xlg.css';
import './Public/Css/lg.css';
import './Public/Css/md.css';
import './Public/Css/sm.css';
import './Public/Css/xs.css';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import './Modules/Translations/index';

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
