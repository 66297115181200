/*
    routování aplikace
*/
import React, { useEffect } from 'react';
import {Routes, Route } from 'react-router-dom';
import Login from '../Modules/Auth/Components/Login';
import NewPassword from '../Modules/Auth/Components/NewPassword';
import ResetPassword from '../Modules/Auth/Components/ResetPassword';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import PersonalData from '../Modules/Customer/Components/PersonalData';
import Signpost from '../Modules/Auth/Components/Signpost';
import AudioRecordings from '../Modules/AudioRecordings/Components/AudioRecordings';
import ConfirmRegistration from '../Modules/Auth/Components/ConfirmRegistration';
import Layout from '../Layout/Layout.js';
import NoMatch from './NoMatch';
import { GET_SETTINGS } from '../GlobalQueries/globalQueries';
import {useQuery} from '@apollo/client';


const Routing = () => {

    const {data} = useQuery(GET_SETTINGS,{
		fetchPolicy: 'network-only',
	});

    useEffect(() => {
 
        const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;
        
        const checkIsIOS = () =>
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        
        if (checkIsIOS()) {
            disableIosTextFieldZoom();
        }

    })

    useEffect(() => {
  
        if(data && data.settings)
        {
            var version = localStorage.getItem("portalVersion");
            if(version != data.settings.portalVersion)
            {
              localStorage.removeItem("au-ap-s");
              localStorage.setItem("portalVersion",data.settings.portalVersion);
              window.location.reload();
            }  
        }
  
    },[data])

    const addMaximumScaleToMetaViewport = () => {

        const el = document.querySelector('meta[name=viewport]');
      
        if (el !== null) {
          let content = el.getAttribute('content');
          let re = /maximum\-scale=[0-9\.]+/g;
      
          if (re.test(content)) {
              content = content.replace(re, 'maximum-scale=1.0');
          } else {
              content = [content, 'maximum-scale=1.0'].join(', ')
          }
      
          el.setAttribute('content', content);
        }
    };
		  
    return (
        <Routes>
        
            <Route exact path='/' element={<Login />}></Route>
            <Route exact path='/reset-password' element={<ResetPassword />}></Route>
            <Route exact path='/new-password/:code' element={<NewPassword />}></Route>
            <Route exact path='/registration/:regCode' element={<ConfirmRegistration />}></Route>
            <Route path='/*' element={<Layout />}>
                <Route exact path='audio-recordings/:membership' element={<AudioRecordings />}></Route>
                <Route exact path='change-password' element={<ChangePassword />}></Route>
                <Route exact path='personal-data' element={<PersonalData />}></Route>
                <Route path="*" element={<NoMatch />} />
            </Route>
            <Route exact path='/signpost' element={<Signpost />}></Route>
          
	        <Route path="*" element={<NoMatch />} />

	    </Routes>
    );
  
}
export default Routing;