import React,{ Component } from 'react';

const Success = ({text}) => {
    return (
    	<div className="alert alert-success text-center">	
    		{text}
    	</div>
    );
}

export default Success;