import { ApolloClient, InMemoryCache, ApolloProvider,ApolloLink,concat } from '@apollo/client';
import Routes from './Routes/index.js'; 
import {GRAPHQL_SERVER_URL} from './Config/index';
import { createUploadLink } from 'apollo-upload-client';
import {resolvers, typeDefs} from './Resolvers/resolvers';
import { AuthProvider,tokenName } from './Modules/Auth/Library/useAuth.js';
import { GET_NOTIFICATIONS } from './GlobalQueries/globalQueries.js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {STRIPE_PKEY} from "./Config/index";
const stripePromise = loadStripe(STRIPE_PKEY);

const App = () => {

    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    customerContext: {
                        merge: true,
                    },
                    notifications:{
                        merge(existing, incoming) {
                            return incoming;
                        }
                    }
                },
            },
        },
    });

    const uploadLink = new createUploadLink({ 
        uri: GRAPHQL_SERVER_URL,
         
    });

    const link = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
          headers: {
            authorization: localStorage.getItem(tokenName) || null,
          }
        });
      
        return forward(operation);
    });

    const client = new ApolloClient({
        cache,
        link:concat(link, uploadLink), 
        resolvers,
        typeDefs
    });

    cache.writeQuery({ 
        query:GET_NOTIFICATIONS,
        data:{
            notifications:[]
        } 
    });
  
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <Elements stripe={stripePromise} options={{locale:"en"}}>
                    <Routes />
                </Elements>
            </AuthProvider>
        </ApolloProvider>
    );
    
}

export default App;