import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import en from './LangTranslations/en.json';
import us from './LangTranslations/us.json';
import cz from './LangTranslations/cz.json';
import sk from './LangTranslations/sk.json';
import ua from './LangTranslations/ua.json';
import de from './LangTranslations/de.json';
import pl from './LangTranslations/pl.json';
import af from './LangTranslations/af.json';
import ar from './LangTranslations/ar.json';
import hy from './LangTranslations/hy.json';
import az from './LangTranslations/az.json';
import be from './LangTranslations/be.json';
import bs from './LangTranslations/bs.json';
import bg from './LangTranslations/bg.json';
import ca from './LangTranslations/ca.json';
import zh from './LangTranslations/zh.json';
import hr from './LangTranslations/hr.json';
import da from './LangTranslations/da.json';
import nl from './LangTranslations/nl.json';
import et from './LangTranslations/et.json';
import fi from './LangTranslations/fi.json';
import fr from './LangTranslations/fr.json';
import gl from './LangTranslations/gl.json';
import el from './LangTranslations/el.json';
import he from './LangTranslations/he.json';
import hi from './LangTranslations/hi.json';
import hu from './LangTranslations/hu.json';
import ind from './LangTranslations/in.json';
import it from './LangTranslations/it.json';
import ja from './LangTranslations/ja.json';
import kk from './LangTranslations/kk.json';
import ko from './LangTranslations/ko.json';
import lv from './LangTranslations/lv.json';
import lt from './LangTranslations/lt.json';
import mk from './LangTranslations/mk.json';
import ms from './LangTranslations/ms.json';
import ne from './LangTranslations/ne.json';
import no from './LangTranslations/no.json';
import fa from './LangTranslations/fa.json';
import pt from './LangTranslations/pt.json';
import ro from './LangTranslations/ro.json';
import ru from './LangTranslations/ru.json';
import sr from './LangTranslations/sr.json';
import sl from './LangTranslations/sl.json';
import es from './LangTranslations/es.json';
import sw from './LangTranslations/sw.json';
import sv from './LangTranslations/sv.json';
import tl from './LangTranslations/tl.json';
import ta from './LangTranslations/ta.json';
import th from './LangTranslations/th.json';
import tr from './LangTranslations/tr.json';
import ur from './LangTranslations/ur.json';
import vi from './LangTranslations/vi.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
      cz,
      sk,
      us,
      ua,
      de,
      pl,
      af,
      ar,
      hy,
      az,
      be,
      bs,
      bg,
      ca,
      zh,
      hr,
      da,
      nl,
      et,
      fi,
      fr,
      gl,
      el,
      he,
      hi,
      hu,
      ind,
      it,
      ja,
      kk,
      ko,
      lv,
      lt,
      mk,
      ms,
      ne,
      no,
      fa,
      pt,
      ro,
      ru,
      sr,
      sl,
      es,
      sw,
      sv,
      tl,
      ta,
      th,
      tr,
      ur,
      vi
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
});