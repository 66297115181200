import {useRef,useState,useEffect} from 'react';
import volumeOn from '../../../Public/Images/Icons/volume-on.svg';
import volumeOff from '../../../Public/Images/Icons/volume-off.svg';
import Helper from '../../../Library/helper';

var startX = 0;
var newX = 0;
var isDraging = false;

const Volume = ({audioRef,videoRef}) => {

    const helper = new Helper();

    const thumbRef     = useRef();
    const progressRef  = useRef();

    const [volume, SetVolume] = useState(50);
    const [isMute, SetIsMute] = useState(false);

    useEffect(() => {

        newX = 50;

        if(!helper.DetectMobAndtablet())
        {
           
            thumbRef.current.addEventListener("mousedown",DragHandler);
            document.addEventListener("mouseup",DropHandler);
            thumbRef.current.addEventListener("touchstart",DragHandlerTouch);
            document.addEventListener("touchend",DropHandlerTouch);

            InitVolume();

            return () => {
                
                if(thumbRef.current){
                    thumbRef.current.removeEventListener("mousedown",DragHandler);
                    thumbRef.current.removeEventListener("touchstart",DragHandlerTouch);
                }
                document.removeEventListener("mouseup",DropHandler);
                document.removeEventListener("touchend",DropHandlerTouch);
            }
        }

    },[]);

    const InitVolume = () => {
        
        if(volume < 0)SetVolume(0);
        if(volume > 100) SetVolume(100);

        if(audioRef.current)
            audioRef.current.volume = volume/100;

        if(videoRef.current)
            videoRef.current.setVolume(volume/100);

        SetThumbPosition(volume);
    }

    const DragHandler = (e) => {
        startX = e.clientX;
        isDraging = true;
        document.addEventListener("mousemove",MouseMove);
    }
    const DropHandler = () => {
        document.removeEventListener("mousemove",MouseMove);
        if((newX || newX == 0) && isDraging) SetVolume(newX);
        isDraging = false;
    }

    const DragHandlerTouch = (e) => {
        startX = e.touches[0].clientX;
        isDraging = true;
        document.addEventListener("touchmove",TouchMove);
    }
    const DropHandlerTouch = () => {
        document.removeEventListener("touchmove",TouchMove);
        if((newX || newX == 0) && isDraging) SetVolume(newX);
        isDraging = false;
    }

    const MouseMove = (e) => {
        SetVol(e.clientX)
    }

    const TouchMove = (e) => {
        const clientX = e.touches[0].clientX; 
        SetVol(clientX)
    }

    const SetVol = (clientX) => {
        SetVolume((vol) => {

            newX = vol + (clientX - startX);
            
            if(newX < 0) newX = 0;
            if(newX > 100) newX = 100;

            SetIsMute((prevMute) => {
                
                if(newX == 0)
                    return true;
                else if(newX > 0 && prevMute)
                {
                    if(audioRef.current)
                        audioRef.current.muted = false;

                    if(videoRef.current)
                        videoRef.current.setMuted(false);
                    return false;
                }
                else
                    return prevMute;
            })
    
            if(!helper.DetectMobAndtablet())
                SetThumbPosition(newX);
    
            if(audioRef.current)
                audioRef.current.volume = parseFloat(newX / 100);
            if(videoRef.current)
                videoRef.current.setVolume(parseFloat(newX / 100));

            return vol;
        })
    }

    const MuteSound = (mute) => {

        SetVolume((vol) => {

            var newVol = vol;
            if(mute)
            {
                if(!helper.DetectMobAndtablet())
                    SetThumbPosition(0);

                newVol = 0;
                if(audioRef.current)
                {
                    audioRef.current.muted = mute;
                    audioRef.current.volume = 0;
                }

                if(videoRef.current)
                {
                    videoRef.current.setMuted(mute);
                    videoRef.current.setVolume(0);
                }
            }
            else if(vol == 0)
            {
                if(!helper.DetectMobAndtablet())
                    SetThumbPosition(newX);
                newVol = newX;

                if(audioRef.current)
                {
                    audioRef.current.muted = mute;
                    audioRef.current.volume = parseFloat(newVol / 100);
                }

                if(videoRef.current)
                {
                    videoRef.current.setMuted(mute);
                    videoRef.current.setVolume(parseFloat(newVol / 100));
                }
            }

            SetIsMute(mute);

            return newVol;
        });
    }

    const SetThumbPosition = (posX) => {
        thumbRef.current.style.transform = "translateX(calc("+(posX)+"px - 50%))";
        progressRef.current.style.width = (posX)+"px";
    }

    return(
        <div className="volume-content">
            {isMute ?
                <img onClick={() => MuteSound(false)} className="volume-img" src={volumeOff} />
            :
                <img onClick={() => MuteSound(true)} className="volume-img" src={volumeOn} />
            }
        
            <div className="volume">
                <div ref={progressRef} className="progress"></div>
                <div ref={thumbRef} className="thumb"></div>
            </div>
        </div>
    )
}

export default Volume;