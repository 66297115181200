const isDev = () => {
    try {
        return process.env.NODE_ENV === "development";
    } catch {
        return false;
    }
};


let graphqlServerUrl              = "https://server.astral.university/graphql";
let serverUrl                     = "https://server.astral.university";
let stripeKey                     = "pk_live_51Ndb4sCnzPvMsrvxL2SSJr6MZL280pfrtFkMUyWAcfHf6v24YChukqlw6DsG6IG9yTd86rRJl5IbXidtHhH9CcbX00VYh9MJ1S";

if (isDev()) {
    graphqlServerUrl              = "http://localhost:8924/graphql";
    serverUrl                     = "http://localhost:8924";
    stripeKey                     = "pk_test_51Ndb4sCnzPvMsrvxE6OHiYIk3w1tdd51WZb505qWpp8fHwTxDy26IPaVtvSs2mpa8HydVS726eEQ3a5MlsZXROry00Vmbfat3p";

}

export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;    
export const AUDIO_URL                       = "Public/Audio";    
export const STRIPE_PKEY                     = stripeKey;


