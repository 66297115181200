import React from 'react';
import {GET_NOTIFICATIONS} from '../GlobalQueries/globalQueries';
import { useQuery,useApolloClient } from '@apollo/client';

const Notification = () => {

	const client = useApolloClient();
	const {data} = useQuery(GET_NOTIFICATIONS);
	  
	setTimeout(() => {
		
		const { notifications } = client.cache.readQuery({ query: GET_NOTIFICATIONS });
		if(notifications.length > 0)
		{
			var arr = [...notifications];
			arr = arr.slice(0,-1);

			client.writeQuery({ 
				query:GET_NOTIFICATIONS,
				data:{
					notifications:arr
				} 
			});
		}
		
	},6000)

	return(
		<div id="notification">
			{data && data.notifications && data.notifications.map((item,index) => (
				<div className="notify" key={index}>
					{item.error &&
						<div className="alert alert-danger text-center">{item.text}</div>
					}
					{item.success &&
						<div className="alert alert-success text-center">{item.text}</div>
					}
					
				</div>
			))}
		</div>
	);

				
		
	
}


export default Notification;
