import gql from 'graphql-tag';

export const GET_CUSTOMER = gql`
  query GetCustomer{
    customerContext{
        customerID  
        name
        surname
        domainID
        domain{
            lang
        }
        firstCategory{
            categoryID
            niceName
        }
        lastViewedCategory{
            categoryID
            niceName
        }
        firstMembership{
          membershipDurationID
          niceName
        }

        lastViewedMembership{
          membershipDurationID
          niceName
        }
        
    }
  }
`;

export const GET_CUSTOMER_FOR_PAYMENT = gql`
  query GetCustomer{
    customerContext{
        customerID  
        name
        surname
        street
        city
        zip
        countryID
        tel   
        email
        domainID
        customerStripeID
        hasStoredPaymentMethod
        domain{
          domainName
          currencyTitle
        }
    }
    allCountries{
      countryID
      name
    }
  }
`;

export const UPDATE_CUSTOMER_DATA = gql`
  mutation UpdateRegistrationData(
    $customerID:ID,
    $email:String,
    $domain:String,
    $name:String,
    $surname:String,
    $city:String,
    $street:String,
    $zip:String,
    $tel:String,
    $countryID:ID,
    $newsletter:Boolean,
    $social:String
  ){
    updateRegistrationData(
      customerID:$customerID,
      email:$email,
      domain:$domain,
      name:$name,
      surname:$surname,
      city:$city,
      street:$street,
      zip:$zip,
      tel:$tel,
      countryID:$countryID,
      newsletter:$newsletter,
      social:$social
    )   
  }
`;
