import Helper from "../../../Library/helper";
import { UPDATE_CUSTOMER_DATA } from "../Queries/customer";

export const UpdateDataWithExtendMembership = (custData,client) => {

    return new Promise(async (resolve,rejected) => {

        try{

            var data = await client.mutate({
                mutation:UPDATE_CUSTOMER_DATA,
                variables:custData
            });

            resolve(data);            

        }catch(err){

            var helper = new Helper();
            err = helper.getApolloErrorText(err);

            rejected(err);
        }
    });
}