import Loading from './Loading';

const Button = ({onClick,className,children,loading,disabled}) => {

    return(
        <button disabled = {disabled || loading} className = {className} onClick = {(e) => onClick(e)}>
            {loading ?
                <Loading useText={false} />
            :
                children
            }
        </button>
    )

}

export default Button;