import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
	{
		notifications @client{
			text
			success
			error
		}
	}
`;

export const GET_HEADER_DATA = gql`
	query HeaderData($lang:String){
		customerContext{
			customerID
			name
			surname	
			memberships{
				membershipDurationID
			}		
		}
		allCategories(lang:$lang){
			categoryID
			name
			photo
			niceName
			showName
		}
		allMembershipsOnlyToPortal(lang:$lang){
			membershipDurationID
			hidden
			name
			photo
			description
			niceName
			showName
			lang
			value
			active
			additionalServices{
				additionalServiceID
				name
				price
				photo
			}
		}
	}
`;

export const GET_DOMAIN_DATA_BY_DOMAIN = gql`
	query DomainDataByDomain($domain:String){
		domainDataByDomain(domain:$domain){
			lang
		}	
	}
`;

export const GET_SETTINGS = gql`
	query Settings{
		settings{
			portalVersion
		}	
	}
`;