import {useState,useEffect, useRef} from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Loading from '../../../GlobalComponents/Loading';
import { usePlayer } from '../../Player/Library/usePlayer';
import close from '../../../Public/Images/Icons/close.png';
import moment from 'moment';
import Modal from 'react-modal';
import Error from '../../../GlobalComponents/Error';
import Payment from '../../Payment/Components/Payment';
import Button from  '../../../GlobalComponents/Button';

Modal.setAppElement("#root");

const AudioRecordings = () => {

    const audioRefs = useRef([]);
    const [showDesc, SetShowDesc] = useState(false);
    const [err, SetErr] = useState(false);
    const [membershipToBuy, SetMembershipToBuy] = useState(false);

    const { membership } = useParams();

    var memID = membership.split("-");
    memID = memID[0];

    const {t,i18n} = useTranslation();
    var {
        AudioPlayHack,
        SetFirstInit,
        SetError,
        selectedAudio,
        isPlaying,
        SetMembershipDurationID,
        audioList,
        membershipDurationID,
        firstInit,
        error,
        loading,
        SetIsPlaying,
        descRef,
        descModalRef
    } = usePlayer();    


    useEffect(() => {
        
        SetError("");
        if(memID && memID != 0)
            SetMembershipDurationID(memID);

        if(memID != membershipDurationID)
            SetFirstInit(false);

    },[memID])

    useEffect(() => {

        if(audioList && audioList.length > 0)
        {
            if(audioList[0].membershipDurationID == memID)
            {
                if(!firstInit || memID != membershipDurationID)
                {
                    var audioIndex = 0;
                    for(let i in audioList)
                    {
                        if(audioList[i].lastViewedAudio == 1)
                        {
                            audioIndex = i;
                            break;
                        }
                    }

                    if(audioRefs.current.length > 0)
                        audioRefs.current[audioIndex].scrollIntoView({ behavior: "smooth", block: "center" });
                                    
                    AudioPlayHack(audioIndex,false);
                }
            }
        }

    },[audioList])

    useEffect(() => {

        var wWidth = window.innerWidth;
        if(selectedAudio && selectedAudio.audioTypeID == 3 && wWidth <= 1200)
        {
            if(isPlaying)
                SetShowDesc(true);
            else
                SetShowDesc(false);
        }
        

    },[selectedAudio,isPlaying])

    const ShowMoreInfo = (e) => {
        e.preventDefault();

        SetShowDesc(true);

    }

    const PlayRec = (item,index) => {

        if(item.customerAudioID || item.activeForUser)
        {
            AudioPlayHack(index,true);
        } 
        else
        {
            SetMembershipToBuy(item.membership);
        }
    }

    const GetHeaderTitle = (audioTypeID) => {

        var title = "";
        switch(audioTypeID)
        {
            case "1": title = t("Recording"); break;
            case "2": title = t("Video"); break;
            case "3": title = t("Article"); break;
        }

        return title;
    }

    const GetHeaderPlayedTitle = (audioTypeID) => {

        var title = "";
        switch(audioTypeID)
        {
            case "1": title = t("Played"); break;
            case "2": title = t("Played"); break;
            case "3": title = t("Viewed"); break;
        }

        return title;
    }

    const GetDescriptionModalHeaderTitle = (audioTypeID) => {

        var title = "";
        switch(audioTypeID)
        {
            case "1": title = t("AudioRecord"); break;
            case "2": title = t("VideoRecord"); break;
            case "3": title = t("Article"); break;
        }

        return title;
    }

    const CloseDescription = () => {

        SetShowDesc(false);

        if(selectedAudio.audioTypeID == 3)
            SetIsPlaying(false);

    }

    return(
        <div id="audio-recording">
            {error || err ?
                <div className="h-100 d-flex justify-content-center align-items-center">
                    <Error text={error || err} />
                </div>
            :
                (loading || !selectedAudio ?
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <Loading />
                    </div>
                :
                    <div className="row h-100 d-flex au-container">

                        <div className={"col col-6 col-lg-12 d-flex audio-desc-container" + (selectedAudio && selectedAudio.audioTypeID != 3 ? " align-items-center" : "")}>
                            {selectedAudio ? 
                                <div ref={descRef} className={"audio-desc" + (selectedAudio.audioTypeID == 3 ? " full" : "")}>
                                    <h1 className={"no-top-margin"}><span>{selectedAudio.name}</span></h1>
                                    <p className="info">
                                         
                                        {selectedAudio.audioTypeID != 3 ? 
                                            <>
                                                {selectedAudio.minutes} 
                                                <span className="circle"></span> 
                                            </>
                                        :null}
                                        {selectedAudio.membership && selectedAudio.membership.name}
                                        
                                    </p>
                                    {selectedAudio.audioTypeID != 3 ?
                                        <a className="more-info" href="#" onClick={(e) => ShowMoreInfo(e)}>{t("MoreInfo")}</a>
                                    :null}

                                    {selectedAudio.audioTypeID == 3 && !selectedAudio.customerAudioID && !selectedAudio.activeForUser ?
                                        <Button className="btn btn-primary" onClick={() => SetMembershipToBuy(selectedAudio.membership)} >Buy</Button>
                                    :
                                        <div className="description" dangerouslySetInnerHTML={{__html:selectedAudio.text}}></div>
                                    }
                                </div>
                            :null}
                        </div>
                        <div className="col col-6 col-lg-12 play-list-container">
                            <div id="play-list">
                                <div className="pl-header">
                                    <div className="static smallest text-center">#</div>
                                    <div>{GetHeaderTitle(selectedAudio.audioTypeID)}</div>
                                    <div className="static text-center">{GetHeaderPlayedTitle(selectedAudio.audioTypeID)}</div>
                                    {selectedAudio.audioTypeID != 3 ?
                                        <div className="static smaller text-center">{t("Length")}</div>
                                    :null}
                                </div>
                                <div className="pl-body">
                                    
                                    {audioList.map((item,index) => {

                                        var audioDuration = item.duration.split(":");
                                        if(audioDuration[0] != "00")
                                            audioDuration = item.duration;
                                        else 
                                            audioDuration = audioDuration[1] + ":" + audioDuration[2];

                                        return(
                                            <div 
                                                ref = {(elm) => audioRefs.current[index] = elm} 
                                                onClick={() => PlayRec(item,index)} 
                                                key={item.audioID} 
                                                className={"pl-item" + (selectedAudio && selectedAudio.audioID == item.audioID  ? " selected" : "")}
                                            >
                                                <div className="static smallest text-center">
                                                    <span className={"first" + (selectedAudio && selectedAudio.audioID == item.audioID && isPlaying && selectedAudio.audioTypeID != 3 ? " playing" : (selectedAudio.audioTypeID == 3 && isPlaying && selectedAudio.audioID == item.audioID ? " reading" : (selectedAudio.audioTypeID == 3 ? " to-read" : "")))}>
                                                        <span>{index + 1}</span>
                                                    </span>
                                                </div>
                                                <div className="title">{item.name}</div>
                                                <div className={"static text-center" + (item.viewed ? " viewed" : "")}>{(item.viewed ? moment(item.dateViewed).format("YYYY-MM-DD"): " - - - ")}</div>
                                                {selectedAudio.audioTypeID != 3 ?
                                                    <div className="static smaller text-center">{audioDuration}</div>
                                                :null}
                                            </div>
                                        )}
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <Modal
                overlayRef = {(elm) => descModalRef.current = elm}
                isOpen={showDesc}
                onRequestClose={() => CloseDescription()}
                overlayClassName={(selectedAudio && selectedAudio.audioTypeID == 3 ? "with-bottom-margin" : "")}
            >
                <div className="modal-content">
                    <div className="modal-header padd">
                        <span>{selectedAudio && GetDescriptionModalHeaderTitle(selectedAudio.audioTypeID)}</span>
                        <img src={close} className="close" onClick={() => CloseDescription()} />
                    </div>
                    <div className="modal-body">
                        {selectedAudio ?
                            <div className="audio-desc">
                                <h1 className="no-top-margin"><span>{selectedAudio.name}</span></h1>
                                <p className="info">
                                    {selectedAudio.audioTypeID != 3 ?
                                        <>
                                            {selectedAudio.minutes} 
                                            <span className="circle"></span> 
                                        </>
                                    :null}
                                    {selectedAudio.membership && selectedAudio.membership.name}
                                </p>
                                <div className="description" dangerouslySetInnerHTML={{__html:selectedAudio.text}}></div>
                            </div>
                        :null}
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={!!membershipToBuy}
                onRequestClose={() => SetMembershipToBuy(false)}
            >
                <div className="modal-content">
                    <div className="modal-header padd">
                        <span>{t("Payment")}</span>
                        <img src={close} className="close" onClick={() => SetMembershipToBuy(false)} />
                    </div>
                    <div className="modal-body">
                        <Payment membership={membershipToBuy} />
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default AudioRecordings;