import React,{ useState } from 'react';
import logo from '../../../Public/Images/logo.svg';
import emailIcon from '../../../Public/Images/Icons/email_24.png';
import Helper from '../../../Library/helper';
import {SEND_FORGOT_PASS_EMAIL} from '../Queries/changePassword';
import Error from '../../../GlobalComponents/Error';
import Success from '../../../GlobalComponents/Success';
import { useTranslation } from 'react-i18next';
import Button from '../../../GlobalComponents/Button';
import { useNavigate } from "react-router-dom";
import {useMutation} from '@apollo/client';

const ResetPassword = ({}) => {

	const {t,i18n} = useTranslation();
	const navigate = useNavigate();

	const [disabled,SetDisabled] = useState(false);
	const [error,SetError] = useState("");
    const [message,SetMessage] = useState("");
	const [email,SetEmail] = useState("");

	const [SendForgotPassEmail,{loading}] = useMutation(SEND_FORGOT_PASS_EMAIL,{
		onCompleted: async (data) => {

			if(data.sendForgotPassEmail == "")
			{
				SetMessage(t('SentEmailForPwdGeneration'));
				setTimeout(() => navigate("/"),4000);
			}
			else
			{
				SetError(data.sendForgotPassEmail);
				setTimeout(() => {SetError('');SetDisabled(false)},4000);
			}
		},
		onError:(err) => {

			const helper = new Helper();
			err = helper.getApolloErrorText(err);
			SetError(err);
			setTimeout(() => {SetError('');SetDisabled(false)},4000);
		}
	});
		      
	const GoBack = () => { 
		navigate("/")
	}
	
	const Generate = () => {
			
		SetDisabled(true);

		var em = email.trim();
		if(em !== '')
		{
			var helper = new Helper();
			if(helper.validateEmail(email))
			{
				SendForgotPassEmail({
					variables:{
						email:em,
						lang: i18n.language
					}
				});
			}
			else
			{
				SetError(t('EmailInWrongFormat'));
				setTimeout(() => {SetError('');SetDisabled(false)},4000);
			}
		}
		else
		{
			SetError(t('NotFilledEmail'));
			setTimeout(() => {SetError('');SetDisabled(false)},4000);
		}
	}
	  
    return (
        <div id="auth-panel">
        
	        <div className="logo">
                <img alt={t('AstralProjection')} src={logo} />
            </div>
        	<div className="card">
				
				<div className="card-header"><span>{t('AstralProjection')}</span></div>
				<div className="card-body">

					<p className="form-group no-top-margin">{t('EnterEmailForPwdGeneration')}</p>
					<div className="form-group">
												
						<div className="input-group">
							<div className="input-group-prepend">
								<img alt="" src={emailIcon} />
							</div>
							<input
								onChange={e => SetEmail(e.target.value)}
								value={email}
								type="text" placeholder={t('Email')} className="form-control" aria-label={t('Email')} />
						</div>
						
					</div>
					<div className="row">
						<div className="col col-8 col-xs-12">
							<Button loading={loading} disabled = {disabled} onClick={(e) => Generate()} className="btn btn-primary btn-block">{t('Generate')}</Button>
						</div>
						<div className="col col-4 col-xs-12 back">
							<Button onClick={() => GoBack()} className="btn btn-danger btn-block">{t('Back')}</Button>
						</div>
					</div>
					
					{ error && <Error text = {error} /> }
					{ message && <Success text = {message} /> }
		    			
	        		
        		</div>
        	</div>
        
        </div>
    );
  
}



export default ResetPassword;
