import gql from 'graphql-tag';

export const GET_CUSTOMER = gql`
  query Context{
    customerContext{
        name
        surname
        customerID
        domainID
        domain{
            lang
        }
        firstCategory{
            categoryID
            niceName
        }
        lastViewedCategory{
            categoryID
            niceName
        }  
        
        firstMembership{
          membershipDurationID
          niceName
        }

        lastViewedMembership{
          membershipDurationID
          niceName
        }
    }
  }
`;