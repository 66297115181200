/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{Fragment,useState,useEffect, useRef } from 'react';
import logo from '../Public/Images/logo_with_text.svg';
import logoutImg from '../Public/Images/Icons/logout.png';
import backToMenuIcon from '../Public/Images/Icons/back.png';
import userImg from '../Public/Images/Icons/user.png';
import keyImg from '../Public/Images/Icons/key.png';
import { useAuth } from '../Modules/Auth/Library/useAuth';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useQuery} from '@apollo/client';
import { GET_HEADER_DATA } from '../GlobalQueries/globalQueries';
import Loading from '../GlobalComponents/Loading';
import {SERVER_URL} from '../Config/index';
import { useParams } from "react-router-dom";
import Payment from '../Modules/Payment/Components/Payment';
import Modal from 'react-modal';
import close from '../Public/Images/Icons/close.png';

Modal.setAppElement("#root");

const Header = (props) => {

	const {Logout} 			 = useAuth();
	const {t,i18n} 			 = useTranslation();
	const { membership }     = useParams();
	
	const [closedHeader, SetClosedHeader] 		  = useState(false);
	const [membershipToBuy, SetMembershipToBuy]   = useState(false);
	const [showPersonalMenu, SetShowPersonalMenu] = useState(false);

	var memID = 0;
	if(membership)
	{
		memID = membership.split("-");
		memID = memID[0];
	}

	const {data,loading,error} = useQuery(GET_HEADER_DATA,{
		variables:{
			lang:i18n.language
		},
		fetchPolicy: 'network-only'
	});

	const ClickOnLink = (closeHeader) => {
		SetClosedHeader(closeHeader);
		SetShowPersonalMenu(false);
	}
	  	  	  
	return (
		
		<header>
			<ul className="top-panel">

				{closedHeader ?
					<li className="menu-icon" onClick = {() => SetClosedHeader(false)}>
						<img className="logout" src={backToMenuIcon} alt="logout" />
					</li>
				:null}
				<li>
					<img onClick={() => SetShowPersonalMenu(!showPersonalMenu)} src={userImg} alt="user" />
					<ul className={(showPersonalMenu ? "open" : "")}>
						<li>
							<Link onClick={() => ClickOnLink(true)} to='/personal-data'>
								<img src={userImg} alt="user" /> {t("PersonalData")}
							</Link>
						</li>
						<li>
							<Link onClick={() => ClickOnLink(true)} to='/change-password'>
								<img src={keyImg} alt="user" /> {t("PasswordChange")}
							</Link>
						</li>
					</ul>
					{showPersonalMenu ?
						<div onClick={() => SetShowPersonalMenu(false)} className="menu-overlay"></div>
					:null}
				</li>
				<li>
					<img className="logout" src={logoutImg} alt="logout" onClick = {() => Logout()} />
				</li>
				
			</ul>
			<div className={"side-bar" + (closedHeader ? " closed" : "")}>

				<img alt="Astrální akademie" className="logo" src={logo} />

				<div className="categories">
					{loading ?
						<Loading />
					:
					<>	
						{data.allMembershipsOnlyToPortal.map((item,index) => {

							var check = true;
							for(let val of data.customerContext.memberships)
							{
								if(val.membershipDurationID == item.membershipDurationID)
									check = false;
							}

							if((!item.hidden || !check) && (item.active == 1 || (item.active == 0 && (data.customerContext.customerID == 17 || data.customerContext.customerID == 19))))
							{
								return(
									<Link onClick={() => SetClosedHeader(true)} key={item.membershipDurationID} className={"course" + (memID == item.membershipDurationID ? " selected" : "") + (index == 0 ? " main" : "")} to={'/audio-recordings/' + item.membershipDurationID + "-" + item.niceName}>
										<img alt={item.name} src={SERVER_URL + "/Public/Images/Membership/stredni_" + item.photo} />
										
										{item.showName ?
											<h3>{item.name}</h3>
										:null}

										{check ?
											<div onClick={() => SetMembershipToBuy(item)} className="badge badge-purple-gold">BUY</div>
										:null}
									</Link>
								)
							}
							else
							{
								return "";
							}
						})}			
						
					</>

					}

				</div>
				

				<div className="dot tl"></div>
				<div className="dot tr"></div>
				<div className="bottom-gradient"></div>
			</div>

			<Modal
                isOpen={!!membershipToBuy}
                onRequestClose={() => SetMembershipToBuy(false)}
            >
                <div className="modal-content">
                    <div className="modal-header padd">
                        <span>{t("Payment")}</span>
                        <img src={close} className="close" onClick={() => SetMembershipToBuy(false)} />
                    </div>
                    <div className="modal-body">
                        <Payment membership={membershipToBuy} />
                    </div>
                </div>
            </Modal>
			
		</header>
				
	);
}

export default Header;
