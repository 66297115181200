import React,{ Fragment, useState } from 'react';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Notification from '../../../Library/notification';
import {GET_CUSTOMER_FOR_PAYMENT} from '../Queries/customer';
import { useMutation, useApolloClient,useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { UsePersonalData } from '../Library/UsePersonalData.js';
import Success from '../../../GlobalComponents/Success';

const PersonalData = () => {

	const {t,i18n} = useTranslation();
	const client = useApolloClient();

    const [allCountries,SetAllCountries] = useState([]);

    const {
        formData,
        error,
        loading,
        success,
        FormHandle,
        ChangeData,
        InitFormData
    } = UsePersonalData();

    useQuery(GET_CUSTOMER_FOR_PAYMENT,{
        fetchPolicy: 'network-only',
        onCompleted:(d)=>{

            SetAllCountries(d.allCountries);
           
            var custData = {
                customerID:             d.customerContext.customerID,
                name:                   d.customerContext.name || "",
                email:                  d.customerContext.email,
                surname:                d.customerContext.surname || "",
                street:                 d.customerContext.street || "",
                city:                   d.customerContext.city || "",
                zip:                    d.customerContext.zip || "",
                countryID:              d.customerContext.countryID,
                tel:                    d.customerContext.tel || ""
            }

            if(d.customerContext.countryID == 239)
            {
                const splitCity = d.customerContext.city.split(", ");
                custData.city   = splitCity[0];
                custData.state  = splitCity[1];
            }
  
            InitFormData(custData); 
        }
    });
	  	  
    return (
    	<div className="padd">		
    			
			<div className="card">
				
				<div className="card-header padd d-flex align-items-center">
					<span>{t('PersonalData')}</span>
				</div>
				<div className="card-body">

                    <>
                        {loading ?
                            <Loading />
                        :
                            <div>
                                <div className="row">
                                    <div className="col col-6 col-xs-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Name")}</label>
                                                <div>
                                                    <input type="text" name="name" className="form-control" value={formData.name} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-6 col-xs-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Surname")}</label>
                                                <div>
                                                    <input type="text" name="surname" className="form-control" value={formData.surname} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-8 col-xs-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Street")}</label>
                                                <div>
                                                    <input type="text" name="street" className="form-control" value={formData.street} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-4 col-xs-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Zip")}</label>
                                                <div>
                                                    <input type="text" name="zip" className="form-control" value={formData.zip} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-4 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("City")}</label>
                                                <div>
                                                    <input type="text" name="city" className="form-control" value={formData.city} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {formData.countryID == 239 ?
                                        <div className="col col-4 col-sm-6 col-xs-12">
                                            <div className="form-group">
                                                <div className="input">
                                                    <label>{t("State")}</label>
                                                    <div>
                                                        <input type="text" name="state" className="form-control" value={formData.state} onChange={(e) => FormHandle(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :null}
                                    <div className="col col-4 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Country")}</label>
                                                <div>
                                                    <select className="form-control" name="countryID" value={formData.countryID} onChange={(e) => FormHandle(e)}>
                                                        {allCountries.map((item,index) => (
                                                            <option value={item.countryID} key={index}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-4 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <div className="input">
                                                <label>{t("Phone")}</label>
                                                <div>
                                                    <input type="text" name="tel" className="form-control payu-card-number" value={formData.tel} onChange={(e) => FormHandle(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="text-right">
                                    <button className="btn btn-primary" onClick={() => ChangeData()}>{t('Change')}</button>
                                </div>

                                {error ?
                                    <Error text={error} />
                                :null}

                                {success ?
                                    <Success text={success} />
                                :null}

                            </div>
                        }
                    </>			
				</div>
			</div>			
    	</div>
    		
    );
  
}

export default PersonalData;
